import React from 'react'
import {Routes, Route, Navigate} from 'react-router-dom'
import AboutUs from '../pages/AboutUs';

import GeneralEngineering from '../pages/GeneralEngineering';
import Home from '../pages/Home';
import Home2 from '../pages/Home2';
import InteriorRemodeling from '../pages/InteriorDesign';
import OurTeam from '../pages/OurTeam';
import Services from '../pages/Services';
import Structural from '../pages/Structural';
import Schedule from '../pages/Schedule';
import Registration from '../pages/Registration';
import Sponsorship from '../pages/Sponsorship';
import Speakers from '../pages/Speakers';
import FirebaseFirestore from '../pages/FirebaseFirestore';
import Gallery from '../pages/Gallery';
import Gallery2 from '../pages/Gallery2';


const Routers =()=> {
  return (
    <Routes>
    <Route path='/' element={<Home/>} />
      <Route path='home' element={<Home/>} />

      <Route path='about-us' element={<AboutUs/>} />
      <Route path='services' element={<Services/>} />
      <Route path='our-team' element={<OurTeam/>} />
      <Route path='general-construction' element={<GeneralEngineering/>} />
      <Route path='interior-remodeling' element={<InteriorRemodeling/>} />
      <Route path='structural' element={<Structural/>} />
      <Route path='schedule' element={<Schedule/>} />
      <Route path='Registration' element={<Registration/>} />
      <Route path='Sponsorship' element={<Sponsorship/>} />
      <Route path='Speakers' element={<Speakers/>} />
      <Route path='Gallery' element={<Gallery/>} />
      <Route path='secret' element={<Gallery2/>} />
      <Route path='Form' element={<FirebaseFirestore/>} />
    </Routes>
  )
}
export default Routers;