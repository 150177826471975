
import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/contact.css'
import '../components/Styles/AboutUs.css'
import {Helmet}from"react-helmet";
import wetp from '../components/Styles/wm1-min.png';
import wetp2 from '../components/Styles/wm2-min.png';
import ab_sec2img from '../images/ab_sec2img.png';

import m3 from '../images/m3-min.png';

import AOS from 'aos';
import 'aos/dist/aos.css';


 const AboutUs =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>Welcome to WealthPath: Learn About Us</title>
      <meta name="description" content="Attend the WealthPath Conference to connect with finance experts and gain exclusive industry insights. Learn about us here!"/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>

      <section className='header_top'>
      <div className='headertp'>
      <br /><br /><br /><br /><br /><br /><br /><br />
        <br />
        <h1 data-aos="zoom-in">About WealthPath <br />Conference</h1>
        <br />
      </div>
      </section>
     
      <section className='med2'>
      <div className='med_mi'>
        <div className='rowwi'>
 
        <div className='mai_col' data-aos="fade-right">
       <h2>
       Our Mission
       </h2>
       <p>
       The WealthPath Conference is dedicated to advancing knowledge and understanding of Shariah-compliant investments among financial professionals, investors, and enthusiasts. Our annual gathering in Houston, Texas, serves as a platform to explore ethical investment practices that align with Islamic financial principles, aiming to foster a community that thrives on integrity, innovation, and inclusivity.
       </p>
       </div>
      
      <div className='mai_col' data-aos="fade-left">
        <img src={wetp} alt="" />
        <div style={{visibility:"hidden"}} id='smbx'>
          <h3>46</h3>
        </div> 
        <img id='wetp2' src={wetp2} alt="" />
       
      </div>
        </div>
      
      </div>
      </section>
<br /><br /><br /><br /><br /><br /><br /><br />
      <section className='med2'>
        <div className='med_mi2' >
        <div className='med_mi2_row'data-aos="fade-right" >
          <div className='med2_sec'>
          <img style={{width:"100%"}} src={m3} alt="" />
     
          </div>
          <div className='med2_sec'data-aos="fade-left">
        <h2>Who We Are</h2>
        <p>Founded in 2023 by a group of financial experts and Islamic scholars, the WealthPath Conference has quickly become one of the most anticipated events in the field of Islamic finance in the United States. Our team is composed of seasoned professionals from diverse backgrounds in finance, education, and technology, all sharing a common goal: to bridge the gap between traditional investment practices and ethical, Shariah-compliant alternatives.
</p>
          </div>
        </div>
        </div>
     
      </section>
     

      <section className='med2'>
      <div className='med_mi'>
        <div className='rowwi' data-aos="fade-right">
 
        <div className='mai_col'>
       <h2>
       What We Do
       </h2>
       <p>
       Each year, the WealthPath Conference brings together thought leaders, industry experts, and practitioners to discuss challenges, opportunities, and the latest trends in the world of Islamic finance. Our sessions cover a broad spectrum of topics, including real estate investments, stocks, mutual funds, and innovative financial technologies that comply with Islamic law.     </p>
       </div>
      
      <div className='mai_col' data-aos="fade-left">
        <img src={wetp} alt="" />
        <div style={{visibility:"hidden"}} id='smbx'>
          <h3>46</h3>
        </div> 
        <img id='wetp2' src={wetp2} alt="" />
       
      </div>
        </div>
      
      </div>
      </section>
<br /><br /><br /><br /><br /><br /><br /><br />
  <br /><br /><br />
<section className='ab_sec'data-aos="zoom-in">
<div className='ab_sec_li'>
    <h2>Why Attend?</h2>
    <p>Attending the WealthPath Conference offers numerous benefits:
</p>

<div className='ab_row'>
<div className='ab_sec_col'>
  <h2>Expert Insights</h2>
  <p>Gain access to cutting-edge knowledge and insights from leading experts in Shariah-compliant finance.</p>
</div>
<div className='ab_sec_col'>
  <h2>Networking Opportunities</h2>
  <p>Connect with over 500 accredited investors and professionals who share your interest in ethical investing.</p>
</div>

</div>

<div className='ab_row'>
<div className='ab_sec_col'>
  <h2>Interactive Sessions</h2>
  <p>Participate in panel discussions, workshops, and Q&A sessions that encourage dialogue and deepen understanding.</p>
</div>
<div className='ab_sec_col'>
  <h2>Networking Opportunities</h2>
  <p>- Enhance your skills and expertise to better serve clients interested in ethical investment options.</p>
</div>

</div>
</div>
</section>
<br /><br />
<section className='.ab_sec' data-aos="zoom-in">
<div className='ab_sec_li'>
<h2>Our Impact</h2>
<p style={{textAlign:"left",fontSize:"20px"}}>Since its inception, the WealthPath Conference has:
<br /><br />
- Attracted participants from across the globe, fostering a diverse and engaged community.
<br />
- Sparked the launch of numerous successful investment ventures aligned with ethical financial principles.
<br />
- Played a key role in advancing the conversation and development of Shariah-compliant financial products.</p>
<br />
<div className='ab_row2'>
<div className='ab_col_sec2'>
  <h2>Join Us</h2>
  <p>Whether you are a seasoned investor, a financial professional, or simply keen on learning more about Islamic finance, the WealthPath Conference is the place to be. We invite you to join us in shaping the future of ethical investing, one discussion at a time.
</p>
</div>

<div className='ab_col_sec2'>
<img style={{width:"100%"}} src={ab_sec2img} alt="" />  
</div>
</div>
</div>

</section>
<br /><br />
<section className="">
            <div className="con">
              <h2 >Register Now!</h2>
              <p>Don’t miss out on the opportunity to be part of this cutting-edge conference. Secure your spot today and start your journey on the WealthPath to successful and ethical investing.</p>
             <br />
              <div className="reg">
              <button id="eventbrite-widget-modal-trigger-890266861147" type="button">Buy Tickets</button>
            </div>
            </div>
          </section>
   </div>
  )
}
export default AboutUs;