
import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/Schedule.css'
import '../components/Styles/AboutUs.css'
import '../components/Styles/Registration.css'
import '../components/Styles/contact.css'
import lgg1 from '../images/33logo.jpg';
import lgg2 from '../images/bun-min.jpeg';
import lgg3 from '../images/lgg3.png';
import lgg4 from '../images/lgg4.png';
import lgg5 from '../images/lgg5.png';
import FirebaseFirestore from './FirebaseFirestore'
import {Helmet}from"react-helmet";
import wetp from '../components/Styles/wm1-min.png';
import wetp2 from '../components/Styles/wm2-min.png';
import ab_sec2img from '../images/ab_sec2img.png';


import AOS from 'aos';
import 'aos/dist/aos.css';
import FirebaseFirestore2 from './FirebaseFirestore2'


 const Sponsorship =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>Sponsors Spotlight at WealthPath Conference - Top Industry Sponsors</title>
      <meta name="description" content="Discover leading industry sponsors at the WealthPath Conference. Join us to connect with top-tier financial experts and innovators!"/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>


      <section className='header_top'>
      <div className='headertp'>
      <br /><br /><br /><br /><br /><br /><br /><br />
        <br />
        <h1>Partner with Us at Wealth <br /> Path  Conference 2024</h1>
        <br />
        <p>Showcase your brand to a highly engaged audience of professionals and investors</p>
        <br />
      </div>
      </section>
     <br /><br /><br />

     <section className='reg_sec'>
     
     <div className='reg_sec2'>
     <h2>Our Valued Partners
     </h2>
    
 <div style={{width:"100%",margin:"auto"}} >
   <div className='sprow'>
    <div className='sprow_col'>
      <a href="https://www.bunyan.io/marketplace"><img src={lgg2} alt="" /></a>
      
    </div>
    <div className='sprow_col'>
      <a href="https://www.questtrustcompany.com/"><img src={lgg4} alt="" /></a>
    
    </div>
    <div className='sprow_col'>
      <a href="http://www.investream.us/"> <img src={lgg5} alt="" />
   </a>
    </div>
   </div>

 </div>

 <div id='id22'>
   <div className='sprow'>
    <div className='sprow_col2'>
    
    <a href="https://www.33percentfund.com/"><img src={lgg1} alt="" /></a>
      
    </div>
    <div className='sprow_col2'>
      <a href="https://www.disruptequity.com/"> <img src={lgg3} alt="" /></a>
    
    </div>

   </div>

 </div>

  <FirebaseFirestore2/>
<br /><br /><br />
       <div className='reg_row'>
         <div className='reg_col'>
         <h2>Join Us</h2>
         <p>Whether you are a seasoned investor, a financial professional, or simply keen on learning more about Islamic finance, the WealthPath Conference is the place to be. We invite you to join us in shaping the future of ethical investing, one discussion at a time.
</p>
         </div>
         <div className='reg_col'>
         <img style={{width:"100%"}} src={ab_sec2img} alt="" /> 
</div>
       </div>
     
     </div>


     </section>
<br /><br />
<section>
  <FirebaseFirestore/>
</section>
    </div>
  )
}
export default Sponsorship;