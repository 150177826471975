import React, { useEffect } from 'react'


import './slide.css'

const Slide = () => {
  useEffect(()=>{
   
  })

  return (
    <div style={{width:"80%",margin:"auto",display:"none"}}>
        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
  <ol class="carousel-indicators">
    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
    
  </ol>
  <div className="carousel-inner">
    <div className="carousel-item active">
   <div className='slidee'>
    <div className='slrow'> 
    <div className='slrow_cola'>
    
          </div>
          <div className='slrow_colb'>
         <h2 style={{lineHeight:"40px"}}>Sarah Thompson</h2>
          <p>"Joining Xceed Office was a game-changer for my freelance career. The vibrant community and the variety of workspaces have not only boosted my productivity but also expanded my professional network. I've never felt more inspired and motivated to achieve my goals!"</p>
   
          </div>
    </div>
    </div>
     </div>


     
  </div>
  <a className="carousel-control-prev"    href="#carouselExampleIndicators" role="button" data-slide="prev">
    <span className="carousel-control-prev-icon"  aria-hidden="true"></span>
    <span className="sr-only">Previous</span>
  </a>
  <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="sr-only">Next</span>
  </a>
</div>
     
    </div>
  )
}

export default Slide;