
import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/contact.css'
import '../components/Styles/AboutUs.css'
import '../components/Styles/Gallery2.css'
import img1 from '../images/ss2-min.png';
import img2 from '../images/ss3-min.png';
import img3 from '../images/ss4-min.png';
import img4 from '../images/ss5-min.png';
import {Helmet}from"react-helmet";


import AOS from 'aos';
import 'aos/dist/aos.css';


 const Gallery2 =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>Welcome to WealthPath: Learn About Us</title>
      <meta name="description" content="Attend the WealthPath Conference to connect with finance experts and gain exclusive industry insights. Learn about us here!"/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>

<br />     

<section>
<div className='cat_main'>
    <h2>CATEGORIES</h2>
    <br />
    <p>Problems trying to resolve the conflict between </p>
<div className='cat_row'>
<div className='cat_cola'>
    <a href=''>shah</a>
   
 <img src={img1} />

</div>
<div className='cat_colb'>
<a href=''>shah</a>
<img src={img2} />

</div>
<div className='cat_colc'>
<a href=''>shah</a>
<img src={img3} />
<br />
<br />
<a href=''>shah</a>
<img src={img4} />

</div>
</div>
</div>
</section>
   </div>
  )
}
export default Gallery2;