
import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/Schedule.css'
import '../components/Styles/AboutUs.css'
import '../components/Styles/contact.css'
import {Helmet}from"react-helmet";
import wetp from '../components/Styles/wm1-min.png';
import wetp2 from '../components/Styles/wm2-min.png';
import ab_sec2img from '../images/ab_sec2img.png';


import AOS from 'aos';
import 'aos/dist/aos.css';


 const Schedule =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>WealthPath Conference 2024 Schedule & Sessions details</title>
      <meta name="description" content="Explore the WealthPath Conference 2024 schedule and session details to optimize your financial future. Join us for key insights and networking!"/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>


      <section className='header_top'>
      <div className='headertp'>
      <br /><br /><br /><br /><br /><br /><br /><br />
          <h1 data-aos="zoom-in">Schedule of WealthPath <br /> Conference</h1>
      </div>
      </section>
<br /><br />
<section className='schedule_sec'>
<div className='schd'>
    <div className='schd_row'>
     <div className='scd_col' data-aos="fade-right">
        <h3>9:30 AM</h3>
     </div>
     <div className='scd_col2' data-aos="fade-left">
        <h2>Welcome and Introduction by the Master  of Ceremonies</h2>
     </div>
 </div>
     <hr/>
</div>
<div className='schd'>
    <div className='schd_row'>
     <div className='scd_col' data-aos="fade-right">
        <h3>9:40 AM - 10:15 AM</h3>
     </div>
     <div className='scd_col2' data-aos="fade-left">
        <h2>Simplifying Real Estate Investing</h2>
        <p><span style={{fontWeight:"600"}}> Speaker: </span> Feras Moussa</p>
 
        <p><span style={{fontWeight:"600"}}> Duration: </span> 35 minutes</p>
     </div>
 </div>
     <hr/>
</div>

<div className='schd'>
    <div className='schd_row'>
     <div className='scd_col' data-aos="fade-right">
        <h3>10:15 AM - 10:45 AM</h3>
     </div>
     <div className='scd_col2' data-aos="fade-left">
        <h2>Why Invest: The Benefits of Passive
Investing</h2>
<p><span style={{fontWeight:"600"}}> Speaker: </span> Professor Gevorg Sargsyan</p>
 
        <p><span style={{fontWeight:"600"}}> Duration: </span> 30 minutes</p>
     </div>
 </div>
     <hr/>
</div>
<div className='schd'>
    <div className='schd_row'>
     <div className='scd_col' data-aos="fade-right">
        <h3>10:45 AM - 11:15 AM</h3>
     </div>
     <div className='scd_col2' data-aos="fade-left">
        <h2>Networking Break</h2>
        <p><span style={{fontWeight:"600"}}> Duration: </span> 30 minutes</p>
     </div>
 </div>
     <hr/>
</div>



<div className='schd'>
    <div className='schd_row'>
     <div className='scd_col' data-aos="fade-right">
        <h3>11:15 AM - 12:00 PM</h3>
     </div>
     <div className='scd_col2' data-aos="fade-left">
        <h2>Panel Discussion: Real Estate vs. Stocks</h2>
        <p><span style={{fontWeight:"600"}}>Panelists:</span>   Feras Moussa, Ala Salous, Gevorg
Sargsyan, Mohammad Ali</p>
        <p><span style={{fontWeight:"600"}}> Duration: </span> 45 minutes</p>
     </div>
 </div>
     <hr/>
</div>

<div className='schd'>
    <div className='schd_row'>
     <div className='scd_col' data-aos="fade-right">
        <h3>12:00 PM - 12:30 PM</h3>
     </div>
     <div className='scd_col2'data-aos="fade-left">
        <h2>Value Investing: What You Don’t  Understand About Stocks</h2>
        <p><span style={{fontWeight:"600"}}>Speaker:</span> Mohammad Ali</p>
        <p><span style={{fontWeight:"600"}}> Duration: </span> 30 minutes</p>
     </div>
 </div>
     <hr/>
</div>


<div className='schd'>
    <div className='schd_row'>
     <div className='scd_col' data-aos="fade-right">
        <h3>12:30 PM - 1:30 PM</h3>
     </div>
     <div className='scd_col2' data-aos="fade-left">
        <h2>Lunch</h2>

        <p><span style={{fontWeight:"600"}}> Duration: </span> 1 hour </p>
     </div>
 </div>
     <hr/>
</div>


<div className='schd'>
    <div className='schd_row'>
     <div className='scd_col' data-aos="fade-right">
        <h3>1:30 PM - 1:45 PM</h3>
     </div>
     <div className='scd_col2' data-aos="fade-left">
        <h2>Dhuhr Prayer</h2>
        <p><span style={{fontWeight:"600"}}> Duration: </span> 15 minutes</p>
     </div>
 </div>
     <hr/>
</div>


<div className='schd'>
    <div className='schd_row'>
     <div className='scd_col' data-aos="fade-right">
        <h3>1:45 PM - 2:15 PM</h3>
     </div>
     <div className='scd_col2' data-aos="fade-left">
        <h2>Islamic Finance: Principles & Practice in
the US</h2>
        <p><span style={{fontWeight:"600"}}>Speaker: </span> Dr. Main Alquda </p>
   
        <p><span style={{fontWeight:"600"}}> Duration: </span> 30 minutes</p>
     </div>
 </div>
     <hr/>
</div>


<div className='schd'>
    <div className='schd_row'>
     <div className='scd_col' data-aos="fade-right">
        <h3>2:15 PM - 2:45 PM</h3>
     </div>
     <div className='scd_col2' data-aos="fade-left">
        <h2>From Oil and Gas to Real Estate: A
journey of discovery and impact!

</h2>
<p><span style={{fontWeight:"600"}}>Speaker: </span> Ala Salous </p>
        <p><span style={{fontWeight:"600"}}> Duration: </span> 30 minutes</p>
     </div>
 </div>
     <hr/>
</div>

<div className='schd'>
    <div className='schd_row'>
     <div className='scd_col' data-aos="fade-right">
        <h3>2:45 PM - 3:15 PM</h3>
     </div>
     <div className='scd_col2' data-aos="fade-left">
        <h2>Networking Break</h2>
   
        <p><span style={{fontWeight:"600"}}> Duration: </span> 30 minutes</p>
     </div>
 </div>
     <hr/>
</div>



<div className='schd'>
    <div className='schd_row' >
     <div className='scd_col' data-aos="fade-right">
        <h3>3:15 PM - 4:00 PM</h3>
     </div>
     <div className='scd_col2'data-aos="fade-left">
        <h2>Unique Investment Opportunities, Think
Outside the Box!</h2>
<p><span style={{fontWeight:"600"}}> Panelists: </span> Feras Moussa, Ala Salous, Ali (moderator), and Dr. Main Alquda</p>
        <p><span style={{fontWeight:"600"}}> Duration: </span> 45 minutes</p>
     </div>
 </div>
     <hr/>
</div>


<div className='schd'>
    <div className='schd_row'>
     <div className='scd_col'data-aos="fade-right">
        <h3>4:00 PM - 4:30 PM</h3>
     </div>
     <div className='scd_col2' data-aos="fade-left">
        <h2>Presentation by Eckard Enterprises
</h2>
<p><span style={{fontWeight:"600"}}> Speaker: </span> Derreck Long</p>

        <p><span style={{fontWeight:"600"}}> Duration: </span> 30 minutes</p>
     </div>
 </div>
     <hr/>
</div>


<div className='schd'>
    <div className='schd_row'>
     <div className='scd_col' data-aos="fade-right">
        <h3>4:30 PM - 5:00 PM</h3>
     </div>
     <div className='scd_col2'data-aos="fade-left">
        <h2>Islamic Estate Planning: Why, When
and How!</h2>
<p><span style={{fontWeight:"600"}}> Speaker: </span> Lubabah Abdullah</p>

        <p><span style={{fontWeight:"600"}}> Duration: </span> 30 minutes</p>
     </div>
 </div>
     <hr/>
</div>
<div className='schd'>
    <div className='schd_row'>
     <div className='scd_col' data-aos="fade-right">
        <h3>5:00 PM - 5:45 PM</h3>
     </div>
     <div className='scd_col2'data-aos="fade-left  ">
        <h2>Open Floor Q&A with All Speakers</h2>

        <p><span style={{fontWeight:"600"}}> Duration: </span> 30 minutes</p>
     </div>
 </div>
     <hr/>
</div>
<br />
<div style={{width:"80%",margin:"auto"}} data-aos="zoom-in">
<div className='ab_row2'>
<div className='ab_col_sec2'>
  <h2>Join Us</h2>
  <p style={{color:"black"}}>Whether you are a seasoned investor, a financial professional, or simply keen on learning more about Islamic finance, the WealthPath Conference is the place to be. We invite you to join us in shaping the future of ethical investing, one discussion at a time.
</p>
</div>

<div className='ab_col_sec2'>
<img style={{width:"100%"}} src={ab_sec2img} alt="" />  
</div>
</div>
</div>


</section>
<br />
<section className="">
            <div className="con">
              <h2 >Register Now!</h2>
              <p>Don’t miss out on the opportunity to be part of this cutting-edge conference. Secure your spot today and start your journey on the WealthPath to successful and ethical investing.</p>
             <br />
              <div className="reg">
              <button id="eventbrite-widget-modal-trigger-890266861147" type="button">Buy Tickets</button>
            </div>
            </div>
          </section>

    </div>
  )
}
export default Schedule;