import React from 'react'
import './footer.css'
import { Link } from 'react-router-dom'
import logo from './logo2.png';

 const Footer =()=> {
  return (
    <>
      <section className="footer__section">
      <div className="footer__width">
      <div className="footer_row">

        <div className='footer_col' >
          
          <h2>Contact Information:</h2>
          <br />
          <h3>Phone</h3>
          <h4>346.387.7187</h4>
          <br />
          <h3>Email</h3>
          <h4>info@wealthpathconference.com  </h4>
        </div>
 
        <div className='footer_col'  >
        <br />
          <h2>Stay Updated</h2>
        <p>For the latest updates, speaker announcements, and more, sign up for our newsletter or follow us on social media.</p>
        <a href="https://www.facebook.com/profile.php?id=61559308352169&mibextid=ZbWKwL" target='_blank'>           <i  style={{fontSize:"30px",color:""}} class="ri-facebook-fill" ></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
          <a href='https://www.instagram.com/wealthpathconference?igsh=anFyZjM2YThwdTg4' target='_blank'> <i style={{fontSize:"30px",color:""}} class="ri-instagram-fill"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
          <a href='linkedin.com/company/wealth-path-conference' target='_blank'> <i style={{fontSize:"30px",color:""}} class="ri-linkedin-fill"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
          
       <br />
        </div>
 
 
        </div>
        
        </div>

      </section> 
    </>
  )
}
export default Footer;