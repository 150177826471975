
import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/contact.css'
import '../components/Styles/AboutUs.css'
import '../components/Styles/Gallery.css'
import {Helmet}from"react-helmet";
import wetp from '../components/Styles/wm1-min.png';
import wetp2 from '../components/Styles/wm2-min.png';
import ab_sec2img from '../images/ab_sec2img.png';
import img1 from '../images/Rectangle 3852-min.png';
import img2 from '../images/r22-min.png';

import img3 from '../images/r33-min.png';
import img4 from '../images/r44-min.png';
import img5 from '../images/r55-min.png';
import img6 from '../images/r66-min.png';
import img7 from '../images/r77-min.png';
import img8 from '../images/r88-min.png';
import img9 from '../images/r99-min.png';
import img10 from '../images/r10-min.png';
import img11 from '../images/r11-min.png';
import img12 from '../images/r12-min.png';
import img13 from '../images/r13-min.png';
import m3 from '../images/m3-min.png';

import AOS from 'aos';
import 'aos/dist/aos.css';


 const Gallery =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>Welcome to WealthPath: Learn About Us</title>
      <meta name="description" content="Attend the WealthPath Conference to connect with finance experts and gain exclusive industry insights. Learn about us here!"/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>

      <section className='header_top'>
      <div className='headertp'>
      <br /><br /><br /><br /><br /><br /><br /><br />
        <br />
        <h1 data-aos="zoom-in">WealthPath Conference <br /> Gallery</h1>
        <br />
      </div>
      </section>

<br />     
     <section className='gallery_sec'>
        <div className='gallery_row'>
               <div className='gallery_cola'>
                <img src={img1} alt="" />
               </div>
               <div className='gallery_cola'>
               <img src={img2} alt="" />
               </div>
               <div className='gallery_colb'>
               <img src={img3} alt="" />
               </div>
        </div>
        <div className='gallery_row'>
              
        <div className='gallery_colb'>
               <img src={img4} alt="" />
               </div>
               <div className='gallery_cola'>
                <img src={img5} alt="" />
               </div>
               <div className='gallery_cola'>
               <img src={img6} alt="" />
               </div>
               </div>
             
               <div className='gallery_row'>
              
        <div className='gallery_colb'>
               <img src={img7} alt="" />
               </div>
               <div className='gallery_colb'>
                <img src={img8} alt="" />
               </div>
               <div className='gallery_colb'>
                <img src={img9} alt="" />
               </div>
               <div className='gallery_cola'>
               <img src={img10} alt="" />
               </div>
              
        </div>
        <div className='gallery_row'>
              
              <div className='gallery_cola'>
                     <img src={img11} alt="" />
                     </div>
                     <div className='gallery_colb'>
                      <img src={img12} alt="" />
                     </div>
                     <div className='gallery_cola'>
                      <img src={img13} alt="" />
                     </div>
                    
              </div>
              
     </section>
<br /><br />
<section className='.ab_sec' data-aos="zoom-in">
<div className='ab_sec_li'>

<div className='ab_row2'>
<div className='ab_col_sec2'>
  <h2>Join Us</h2>
  <p>Whether you are a seasoned investor, a financial professional, or simply keen on learning more about Islamic finance, the WealthPath Conference is the place to be. We invite you to join us in shaping the future of ethical investing, one discussion at a time.
</p>
</div>

<div className='ab_col_sec2'>
<img style={{width:"100%"}} src={ab_sec2img} alt="" />  
</div>
</div>
</div>

</section>
<br /><br />
<section className="">
            <div className="con">
              <h2 >Register Now!</h2>
              <p>Don’t miss out on the opportunity to be part of this cutting-edge conference. Secure your spot today and start your journey on the WealthPath to successful and ethical investing.</p>
             <br />
              <div className="reg">
              <button id="eventbrite-widget-modal-trigger-890266861147" type="button">Buy Tickets</button>
            </div>
            </div>
          </section>
   </div>
  )
}
export default Gallery;