import { initializeApp } from "firebase/app";
import {getFirestore} from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyBPOCeInz1ojYX3AiQpf9vN4WtvQr0m7hA",
  authDomain: "modern-kitchen-8c86f.firebaseapp.com",
  databaseURL: "https://modern-kitchen-8c86f-default-rtdb.firebaseio.com",
  projectId: "modern-kitchen-8c86f",
  storageBucket: "modern-kitchen-8c86f.appspot.com",
  messagingSenderId: "693162460767",
  appId: "1:693162460767:web:20ef5bf037b6d3dfa53dea"
};
  // Initialize Firebase
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  export const database = getFirestore(app);


  